import type { FC } from 'react';
import React, { useRef, useCallback } from 'react';
// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Subscribe } from 'unstated';

import {
	VIEW_PAGE_FAVORITE_BUTTON_EXPERIENCE,
	ExperienceStart,
} from '@confluence/experience-tracker';
import { HeaderItemsContainer, HEADER_ITEM_KEYS } from '@confluence/header-items-manager';
import {
	PageSegmentLoadStart,
	SSRMouseEventWrapper,
	SSR_FAVORITE_BUTTON_METRIC,
} from '@confluence/browser-metrics';
import { Attribution, ErrorBoundary } from '@confluence/error-boundary';
import { useSessionData } from '@confluence/session-data';

import { FavouriteButtonComponent } from './FavouriteButtonComponent';
import { FAVOURITE_BUTTON_METRIC } from './perf.config';

type FavouriteButtonProps = {
	contentId: string;
};

export const FavouriteButton: FC<FavouriteButtonProps> = ({ contentId }) => {
	const { isLoggedIn } = useSessionData();
	const isItemMarked = useRef(false);
	const markItemRef = useRef<((item: HEADER_ITEM_KEYS) => void) | undefined>();

	const buttonRendered = useCallback(() => {
		if (!isItemMarked.current && markItemRef.current) {
			isItemMarked.current = true;
			markItemRef.current(HEADER_ITEM_KEYS.PAGE_FAVOURITE);
		}
	}, []);

	if (!isLoggedIn) {
		return null;
	}

	return (
		<ErrorBoundary attribution={Attribution.DISCO}>
			<PageSegmentLoadStart key={contentId} metric={FAVOURITE_BUTTON_METRIC} />
			<ExperienceStart id={contentId} name={VIEW_PAGE_FAVORITE_BUTTON_EXPERIENCE} />
			<Subscribe to={[HeaderItemsContainer]}>
				{({ addItem, markItem }: HeaderItemsContainer) => {
					addItem(HEADER_ITEM_KEYS.PAGE_FAVOURITE);
					markItemRef.current = markItem;
					return (
						<SSRMouseEventWrapper metricName={SSR_FAVORITE_BUTTON_METRIC}>
							<FavouriteButtonComponent contentId={contentId} onRender={buttonRendered} />
						</SSRMouseEventWrapper>
					);
				}}
			</Subscribe>
		</ErrorBoundary>
	);
};
