import type { FC } from 'react';
import React, { useEffect } from 'react';
import { useQuery } from 'react-apollo';
import { styled } from '@compiled/react';

import {
	VIEW_PAGE_FAVORITE_BUTTON_EXPERIENCE,
	ExperienceSuccess,
	ExperienceFailure,
} from '@confluence/experience-tracker';
import { ErrorDisplay } from '@confluence/error-boundary';
import { PageSegmentLoadEnd } from '@confluence/browser-metrics';
import { PageStarButton } from '@confluence/action-buttons/entry-points/pageStar';
import { STAR_BUTTON_SHORTCUT } from '@confluence/shortcuts';

import { FavouriteButtonPlaceholder } from './FavouriteButtonPlaceholder';
import { FavouriteButtonQuery } from './FavouriteButtonQuery.graphql';
import { FAVOURITE_BUTTON_METRIC } from './perf.config';
import type {
	FavouriteButtonQuery as QueryData,
	FavouriteButtonQueryVariables as QueryVars,
} from './__types__/FavouriteButtonQuery';

type FavouriteButtonComponentProps = {
	contentId: string;
	onRender?: () => void;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonSpacer = styled.div({
	minWidth: '30px',
});

export const FavouriteButtonComponent: FC<FavouriteButtonComponentProps> = ({
	contentId,
	onRender,
}) => {
	const { data, loading, error } = useQuery<QueryData, QueryVars>(FavouriteButtonQuery, {
		variables: {
			contentId,
		},
		fetchPolicy: 'cache-first',
	});

	const contentType = data?.singleContent?.type;

	const hasReadPermission = Boolean(
		data?.singleContent?.operations?.filter(
			(operation) => operation?.targetType === contentType && operation?.operation === 'read',
		).length,
	);

	useEffect(() => {
		if (onRender && data) {
			onRender();
		}
	});

	if (error) {
		return (
			<>
				<ExperienceFailure name={VIEW_PAGE_FAVORITE_BUTTON_EXPERIENCE} error={error} />
				<ErrorDisplay error={error} />
			</>
		);
	}

	if (loading) {
		return <FavouriteButtonPlaceholder />;
	}

	const isStarred = Boolean(data?.singleContent?.metadata.currentuser?.favourited?.isFavourite);

	return (
		<>
			{hasReadPermission && (
				<ButtonSpacer>
					<PageStarButton
						contentId={contentId}
						isStarred={isStarred}
						shortcut={STAR_BUTTON_SHORTCUT}
						tooltipPosition="bottom"
						analytics={{
							source: 'FavouriteButton',
							attributes: {
								componentVersion: 'v3',
								pageType: contentType,
							},
						}}
					/>
				</ButtonSpacer>
			)}
			<ExperienceSuccess
				name={VIEW_PAGE_FAVORITE_BUTTON_EXPERIENCE}
				attributes={{
					isFavouritedByCurrentUser: isStarred,
				}}
			/>
			<PageSegmentLoadEnd
				key={contentId}
				metric={FAVOURITE_BUTTON_METRIC}
				customData={{ isVisible: hasReadPermission }}
			/>
		</>
	);
};
